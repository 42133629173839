<template>
  <div id="camera-container">
    <video
      id="videoCamera"
      :width="videoWidth"
      :height="videoHeight"
      autoplay
    ></video>
    <canvas
      style="display: none"
      id="canvasCamera"
      :width="videoWidth"
      :height="videoHeight"
    ></canvas>
    <div v-if="imgSrc" class="img_bg_camera">
      <p>效果预览</p>
      <img :src="imgSrc" alt class="tx_img" />
    </div>
    <div class="button">
      <el-button @click="openCamera" type="primary">打开摄像头</el-button>
      <el-button @click="closeCamera" type="warning">关闭摄像头</el-button>
      <el-button @click="takePhoto" type="success">拍照</el-button>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
export default {
  setup() {
    const videoWidth = ref(250);
    const videoHeight = ref(350);
    const imgSrc = ref("");
    const canvas = ref(null);
    const ctx = ref(null);
    const video = ref(null);
    // const openVideo = ref(false);
    onMounted(() => {
      openCamera();
    });
    // 调用权限（打开摄像头功能）
    function openCamera() {
      canvas.value = document.getElementById("canvasCamera");
      ctx.value = canvas.value.getContext("2d");
      video.value = document.getElementById("videoCamera");
      video.value.style.display = "block";
      // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          const getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            //不存在则报错
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      const constraints = {
        audio: false,
        video: {
          width: videoWidth.value,
          height: videoHeight.value,
          transform: "scaleX(-1)",
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in video.value) {
            video.value.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            video.value.src = window.URL.createObjectURL(stream);
          }
          video.value.onloadedmetadata = function () {
            video.value.play();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //  绘制图片（拍照功能）
    function takePhoto() {
      // canvas画图
      ctx.value.drawImage(
        video.value,
        0,
        0,
        videoWidth.value,
        videoHeight.value
      );
      // 获取图片base64链接
      const image = canvas.value.toDataURL("image/png");
      // console.log(dataURLtoFile(image, 1));
      imgSrc.value = image; //赋值并预览图片
    }
    // 关闭摄像头
    function closeCamera() {
      video.value.srcObject.getTracks()[0].stop();
    }
    // // base64转文件，此处没用到
    // function dataURLtoFile(dataurl, filename) {
    //   const arr = dataurl.split(",");
    //   const mime = arr[0].match(/:(.*?);/)[1];
    //   const bstr = atob(arr[1]);
    //   let n = bstr.length;
    //   const u8arr = new Uint8Array(n);
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
    //   return new File([u8arr], filename, { type: mime });
    // }
    return {
      videoWidth,
      videoHeight,
      imgSrc,
      openCamera,
      closeCamera,
      takePhoto,
    };
  },
};
</script>

<style lang="scss" scoped>
#camera-container {
}
</style>
